import React, { useMemo, useEffect } from 'react'
import { I18n, hooks } from '@front/volcanion'

import info from '../info'
import config from '../constants'

import StorageManager from '@front/squirtle/services/storage'
import Provider from '@front/volcanion/provider'

import { ThemeProvider } from '@mui/material/styles'
import { SVG, Image, Loader, SnackbarProvider } from '@front/squirtle'

import IdentityUtils from '@front/squirtle/utils/identity'
import getTranslations from './translations'

import CssBaseline from "@mui/material/CssBaseline"
I18n.setI18nConfig(getTranslations)

import useBlobHandler from '@front/squirtle/hooks/useBlobHandler'
import useNotification from '@front/squirtle/hooks/useNotification'
import useRouter from '@front/squirtle/components/Router/useRouter'

import Pages from './pages'

const overrides = {
  MuiInputLabel: {
    defaultProps: {
      size: 'small',
      shrink: true,
    }
  },
}

const Main = () => {
  const identity = hooks.useIdentity()
  const isReady = hooks.useReadyStatus()
  const [mode] = hooks.useGlobalState('mode')
  const theme = useMemo(() => IdentityUtils.getTheme(identity, mode, overrides), [identity, mode, overrides])
  const CLIENT_NAME = hooks.useRelayConstant('CLIENT_NAME')

  useEffect(() => {
    document.title = CLIENT_NAME
  }, [CLIENT_NAME])

  return (
    <Loader isLoading={!isReady} nativeLoader>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
          <Pages />
        </SnackbarProvider>
      </ThemeProvider>
    </Loader >
  )
}

const MemoMain = React.memo(Main)
const App = () => {
  return (
    <Provider
      storage={{ whitelist: [], manager: StorageManager }}
      relay={{ info, config }}
      model={{
        auto_load: [
        ]
      }}
      global={{ persist: ['persist'] }}
      main={{
        useRouter,
        useNotification,
        useBlobHandler,
        ImageRenderer: Image,
        SVGRenderer: SVG,
      }}
    >
      <MemoMain />
    </Provider>
  );
};

export default React.memo(App);