import React, { useEffect } from 'react'
import clsx from 'clsx'

import { hooks } from '@front/volcanion'

import { Loader, Router, Routes, Route } from '@front/squirtle'

import './styles.scss'

import ResetPassword from './ResetPassword'

const PublicRouter = () =>
  <Routes>
    <Route path='/static/reset-password/*' element={<ResetPassword />} />
  </Routes>


const MemoRouter = React.memo(PublicRouter)

const withDrawer = Component => props => {
  const [isDrawerOpen] = hooks.useGlobalState('isDrawerOpen')
  const { CPP_URL } = hooks.useRelayConstants(['CPP_URL'])
  return <Component isDrawerOpen={isDrawerOpen} CppURL={CPP_URL}{...props} />
}


const PublicRoute = ({ isDrawerOpen }) =>
  <div className={clsx('root', { ['shift']: isDrawerOpen })}>
    <Router>
      <MemoRouter />
    </Router>
  </div >


const DrawerRoute = React.memo(withDrawer(PublicRoute))

const Pages = props => {
  const isReady = hooks.useReadyStatus()
  const [, setMode] = hooks.useGlobalState('mode')

  useEffect(() => {
    setMode('light')
  }, [])

  return (
    <Loader isLoading={!isReady}>
      <DrawerRoute />
    </Loader>
  )
};
const StaticPublicRoute = React.memo(Pages)

export default React.memo(StaticPublicRoute)