import React from 'react'
import { hooks } from '@front/volcanion'

import { Box } from '@mui/material'

import ResetPasswordFrame from '@static/frames/ResetPassword'

const Resetpassword = props => {
  const { token } = hooks.useParams()
  return (
    <Box>
      <ResetPasswordFrame token={token} />
    </Box>
  )
}

export default Resetpassword
