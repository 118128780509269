import React from 'react'

import { Box } from '@mui/material'

import {
  Header,
  FormContent,
  Extra
} from './'

const ResetPassword = ({ _box, backgroundImagePath }) =>
  <Box sx={{
    height: 1,
    width: 1,
    backgroundImage: `url(${backgroundImagePath})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundAttachment: 'fixed',
    backgroundPosition: 'bottom'
  }}>
    <Box sx={{
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'space-betwwen',
      borderRadius: 1,
      ..._box
    }}>
      <Header />
      <FormContent />
      <Extra />
    </Box>
  </Box >


export default ResetPassword
