// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[4].use[1]!../../node_modules/react-phone-input-2/lib/material.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body{background-color:"transparent";margin:0}html{scroll-behavior:smooth}.special-label{background:inherit !important}`, "",{"version":3,"sources":["webpack://./src/pages/styles.scss"],"names":[],"mappings":"AAEA,KACE,8BAAA,CACA,QAAA,CAGF,KACE,sBAAA,CAGF,eACE,6BAAA","sourcesContent":["@import \"react-phone-input-2/lib/material.css\";\n\nbody {\n  background-color: 'transparent';\n  margin: 0;\n}\n\nhtml {\n  scroll-behavior: smooth;\n}\n\n.special-label {\n  background: inherit !important;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
