import React from 'react'
import { I18n, CDNImage, hooks } from '@front/volcanion'

import { Box, Typography } from '@mui/material'

const Header = props => {
  const [isMobile] = hooks.useFormState('isMobile')
  return (
    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-evenly' }}>
      <Box p={2}>
        <CDNImage name={'logo'} category={'images'} />
      </Box>
      <Box>
        <Typography fontWeight='bold' variant={'h6'} mb={2} fontSize={!isMobile ? 'h6.fontSize' : 43} textAlign="center"> {I18n.t('password.new.title')} </Typography>
        <Typography textAlign="center" mt={2} fontSize={!isMobile ? 'h6.fontSize' : 33}>{I18n.t('password.new.description')} </Typography>
      </Box>
    </Box>
  )
}


export default Header
