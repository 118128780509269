import React, { useCallback } from 'react'
import { Form, hooks } from '@front/volcanion'

import Callbacks from './callbacks'

const withForm = Component => ({ form_name = 'reqset_password_form' }) => {
  const { openNotification } = hooks.useNotification()
  const { token } = hooks.useSearchParams()
  const [, changePassword] = hooks.useAuthFunction('changePassword')

  const isMobile = !!navigator?.userAgent?.match(/(iPhone|iPod|iPad|Android|BlackBerry|android|iphone)/)

  const formProps = {
    name: form_name,
    initialState: { isReadOnly: false, token: _.head(token), isMobile },
    initialValues: { password: undefined },
    status: { isReady: true },
    onSubmitSuccess: useCallback(Callbacks.onSubmitSuccessHandler(), []),
    onSubmitFailed: useCallback(Callbacks.onSubmitFailedHandler(), []),
    beforeSubmit: useCallback(Callbacks.beforeSubmitHandler(openNotification), [openNotification]),
    onSubmit: useCallback(Callbacks.onSubmitHandler(changePassword), [changePassword])
  }

  const mergedProps = {

  }

  return (
    <Form {...formProps}>
      <Component {...mergedProps} />
    </Form>
  )
}

export default withForm
