export default {
  password: {
    label: "Mot de passe",
    confirm: "Confirmation mot de passe",
    token: {
      expired: "Demande de changement de mot de passe expiré."
    },
    success: {
      title: "Félicitations !",
      description: "Votre nouveau mot de passe vient d’être mis à jour.",
    },
    error: {
      not_same: "Mot de passe non identique"
    },
    new: {
      title: "Réinitialisation du mot de passe",
      description: "Veuillez entrer un nouveau mot de passe"
    },
    rules: {
      length: "8 caractères",
      upper: "une majuscule",
      lower: "une minuscule",
      number: "un chiffre"
    },
    complexity: {
      level: "Complexité du mot de passe :",
      weak: "Faible",
      good: "Moyenne",
      strong: "Élevée"
    },
    redirect: {
      app: "Continuer dans l'application",
      web: "Continuer sur le web booker"
    }
  },
  action: {
    save: 'Sauvegarder'
  }
}