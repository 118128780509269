import React, { useCallback } from 'react'

import { I18n } from '@front/volcanion'

import PasswordComplexity from '@front/squirtle/elements/PasswordValidation'

import { Box, Grid } from '@mui/material'
import { TextField, Loader } from '@front/squirtle'
import LoadingButton from '@mui/lab/LoadingButton'

import { ValidationUtils } from '@front/volcanion/utils'

const FormContent = ({ hidden, password, isMobile, requestSubmit, isSubmitting }) => {
  const validatePassword = useCallback((value) => {
    const errors = _.filter(ValidationUtils.validatePassword(value), ['isValid', false])
    return !_.isEmpty(errors)
  }, [ValidationUtils.validatePassword])

  return (
    <Box hidden={!!hidden} sx={{ flex: 1 }}>
      <Grid container rowSpacing={10} justifyContent={'center'}>
        <Grid item xs={12}>
          <TextField
            placeholder={I18n.t('password.label')}
            name='password'
            type='password'
            validate={validatePassword}
            required
            InputProps={!!isMobile ? { inputProps: { sx: { fontSize: 33 } } } : {}}
          />
          <PasswordComplexity value={password} _container={{ sx: { pt: 2, pb: 2 } }} disableOnSuccess />
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder={I18n.t('password.confirm')}
            name='password_new'
            type='password'
            validate={validatePassword}
            required
            InputProps={!!isMobile ? { inputProps: { sx: { fontSize: 33 } } } : {}}
          />

        </Grid>
        <Grid item xs={10}>
          <LoadingButton
            fullWidth
            onClick={requestSubmit}
            loading={!!isSubmitting}
            loadingIndicator={<Loader
              isLoading={!!isSubmitting}
              _loader={{ style: { objectFit: 'contain' }, variant: null }}
              _container={{ style: { padding: 10 } }}
            />}
            variant='contained'
            sx={{ fontSize: !!isMobile ? 33 : 16 }}
          >
            {I18n.t('action.save')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Box >
  )
}
export default FormContent
