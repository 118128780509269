import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const isFormSubmitted = hooks.useFormStatus('isSubmitted')
  const isSubmitting = hooks.useFormStatus('isSubmitting')
  const [{ isValidToken, isMobile }] = hooks.useFormState()
  const [password, password_new] = hooks.useFieldValues(['password', 'password_new'])
  const requestSubmit = hooks.useFormFunction('requestSubmit')

  const hidden = !!isFormSubmitted || !isValidToken

  const mergedProps = {
    isSubmitting,
    hidden,
    isMobile,
    requestSubmit,
    password
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
