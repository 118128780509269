import React from 'react'
import Component from './component'
import withContainer from './container'
import withForm from './form'

import Header from './Header'
import FormContent from './Content'
import Extra from './Extra'

export {
  Component,
  withContainer,
  withForm,
  Header,
  FormContent,
  Extra,
}

export default React.memo(withForm(withContainer(Component)))
