export default {
  password: {
    label: "Password",
    confirm: "Confirm password",
    token: {
      expired: "Expired password change request."
    },
    success: {
      title: "Congratulation !",
      description: "Your new password has just been updated.",
    },
    error: {
      not_same: "Password not identical"
    },
    new: {
      title: "Reset password",
      description: "Please enter new password"
    },
    rules: {
      length: "8 caractères",
      upper: "une majuscule",
      lower: "une minuscule",
      number: "un chiffre"
    },
    complexity: {
      level: "Password complexity :",
      weak: "Low",
      good: "Medium",
      strong: "High"
    }
  },
  action: {
    save: 'Save'
  }
}

