import React from 'react'

import { hooks } from '@front/volcanion'

const withContainer = Component => props => {
  const isFormSubmitted = hooks.useFormStatus('isSubmitted')
  const { CLIENT_BOOKER_SITE, CLIENT_BOOKER_APP } = hooks.useRelayConstants(['CLIENT_REDIRECT_URL', 'CLIENT_BOOKER_SITE', 'CLIENT_BOOKER_APP'])
  const [{ isValidToken, isMobile }] = hooks.useFormState()
  const isApple = navigator.userAgent.match(/(iPhone|iPod|iPad|iphone)/)
  const hidden = !!isValidToken && !isFormSubmitted

  const mergedProps = {
    hidden,
    isMobile,
    isApple,
    isValidToken,
    CLIENT_BOOKER_SITE,
    CLIENT_BOOKER_APP,
  }

  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
