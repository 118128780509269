import React, { useCallback, useEffect } from 'react'

import { hooks } from '@front/volcanion'
import Callbacks from './callbacks'

const withContainer = Component => props => {
  const { openNotification } = hooks.useNotification()
  const [{ token, isMobile }, setFormState] = hooks.useFormState()

  const [, validateResetPasswordToken] = hooks.useAuthFunction('validateResetPasswordToken')

  const validateToken = useCallback(Callbacks.validateTokenHandler(validateResetPasswordToken, openNotification, setFormState), [validateResetPasswordToken, openNotification, setFormState])

  useEffect(() => {
    !!token && validateToken(token)
  }, [token])

  const isMobileStyle = {
    m: 1,
    width: 1,
    height: 1,
  }

  const isDesktopStyle = {
    m: 0,
    boxShadow: 6,
    height: 3 / 4
  }

  const _box = {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-betwwen',
    borderRadius: 1,
    p: 4,
    ...(!!isMobile ? isMobileStyle : isDesktopStyle),
  }

  const backgroundImagePath = hooks.useCDNAsset('static-background', 'images')

  const mergedProps = {
    backgroundImagePath,
    _box
  }


  return (
    <Component {...mergedProps} />
  )
}

export default withContainer
