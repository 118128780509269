import { I18n } from '@front/volcanion'

class Callbacks {
  static onSubmitSuccessHandler() {
    return function onSubmitSuccess(result, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 166 ~ Callbacks ~ onSubmitSuccess ~ result", result)
    }
  }
  static onSubmitFailedHandler() {
    return function onSubmitFailed(err, values, extra, meta, state) {
      console.log("🚀 ~ file: callbacks.js ~ line 171 ~ Callbacks ~ onSubmitFailed ~ err", err, meta)
    }
  }
  static beforeSubmitHandler(openNotification) {
    return function beforeSubmit({ password, password_new }, extra, meta, state) {
      if (password !== password_new) {
        openNotification(I18n.t('password.error.not_same'), { variant: 'error' })
        return false
      }
      return true
    }
  }
  static validateTokenHandler(validateResetPasswordToken, openNotification, setFormState) {
    return async function validateToken(token) {
      try {
        await validateResetPasswordToken({ token })
        setFormState({ isValidToken: true })
      } catch (err) {
        openNotification(I18n.t('password.token.expired'), { variant: 'error' })
        setFormState({ isValidToken: false })
      }
    }
  }
  static onSubmitHandler(changePassword) {
    return async function onSubmit({ password, ...values }, extra, meta, { token, ...state }) {
      try {
        await changePassword({ token, password })
      }
      catch (err) {
        console.log(err)
      }
    }
  }
}


export default Callbacks
